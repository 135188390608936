import React from 'react'
import homeImg from "../assets/HomeIMG1.svg"
import homeImg2 from "../assets/HomeIMG5.png"
import homeImg3 from "../assets/HomeIMG6.png"
import homeImg4 from "../assets/HomeIMG6.png"
import Button from './Button'
import Banner from "../assets/banner.mp4"
import HighlightText from '../pages/HighlightText'
import CTAButton from "../pages/Button"
import Card from '../components/Card'
import backgroundImage from "../assets/bg1.svg"
import backgroundImage1 from "../assets/HomeIMG2.svg"
import locationIMG from "../assets/LocationIMG.svg"
import orderIMG from "../assets/orderIMG.svg"
import comparedIMG from "../assets/comparedIMG.svg"
import mealIMG from "../assets/mealIMG.svg"




const Home = ({isLoggedIn}) => {
  return (
    <div className=' '>
     
     <div className='flex  flex-col justify-between  w-screen   mx-auto gap-x-12 gap-y-0  py-10 bg-[#BB3226]'>
        <div className='text-center text-4xl font-semibold mt-7 text-richblack-100'>
            Empower Your Future with
            <HighlightText text={"FindAi"} />
        </div>

        <div className=' mt-4 w-[90%] text-center text-lg font-bold text-richblack-100 ml-[20px]'>
        Develop an AI engine capable of effectively comparing diverse datasets,
                    addressing the complexities of disparate data types, structures, and scales.
                    The system should provide         </div>

 <div className='flex flex-row gap-7 mt-10 items-center ml-[550px]'>
            <CTAButton active={true} linkto={"/signup"}> 
                Learn More
            </CTAButton>

            <CTAButton active={false} linkto={"/login"}> 
                Book a Demo
            </CTAButton>
        </div>

        <div className='  ml-[350px] my-10 items-center shadow-blue-200 w-screen'>
            <video
            muted
            loop
            autoPlay
            >
            <source  src={Banner} type="video/mp4" />
            </video>
        </div>

        </div>
      
      <div className='flex flex-row  w-full h-[594px]  mx-auto gap-x-12 gap-y-0 py-28 justify-evenly '
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover" }}
      >
 
       <div className='w-11/12 max-w-[450px]'>
            <h className='text-[#000000] font-bold text-[1.875rem] leading-[2.375rem] w-[407px] h-[80px]'>
            Best deals <span className=' text-red-900 italic'>Tasty Noodles</span>
            </h>
            <p className='text-[1.125rem] leading[1.625rem] mt-5'>
              <span className='text-[#000000]'>
              Enjoy the large size of sandwiches. Complete 
              perfect slice of sandwiches.
              </span>

              <br/>
              </p>
          <br/>
          <br/>
          <div className='flex flex-row  mt-10 items-center W-[407PX] gap-2 text-[#ffffff]'>
            <CTAButton active={true} linkto={"/signup"}> 
            PROCEED TO ORDER <span className=' font-bold  text-[#ffffff] text-[30px]'>></span>
            </CTAButton>

            {/* <CTAButton active={false} linkto={"/login"}> 
                Book a Demo
            </CTAButton> */}
        </div>

        </div>
  

        <div className='relative w-11/12 max-w-[470px] mb-2 bottom-9'
          style={{
            backgroundImage: 'radial-gradient(85.93% 85.93% at 50% 50%, rgba(205, 205, 205, 0.8) 0%, rgba(196, 196, 196, 0) 100%)',
            transform: 'rotate(-180deg) mix-blend-overlay', // Angle
          }}
        >
            <img src={homeImg}
                alt="Pattern"
                width={588}
                height={504}
                loading="lazy"/>
                </div> 
        </div>
        
        <div className='flex   justify-evenly items-center w-screen   mx-auto gap-x-12 gap-y-0 py-20 flex-col  px-28'
          style={{ backgroundImage: `url(${backgroundImage1})`, backgroundSize: "cover" }}
        >
         
            <h className='text-bold font-semibold text-[1.875rem] leading-[2.375rem] text-[#000000]'>
            How does it work
            </h>
            <br /><br />
        
         <div className=' flex flex-row w-[1324px] h-[239px] gap-32 opacity-1 top-3'>
            <div className='w-[307px] h-[239px]  opacity-1 justify-between items-center'>
               <div>
                    <img src={locationIMG}
                      alt="Pattern"
                      width={168}
                      height={112}
                      loading="lazy"/>
               </div>
              
                <div>
                <h1 className=''>Select location</h1>
                <p className=''>Choose the location where your food will be delivered.</p>
                </div>
               
             </div>

            <div className='relative w-11/12 max-w-[550px]   '>
              <img src={orderIMG}
                alt="Pattern"
                width={788}
                height={604}
                loading="lazy"/>
                <h1 className=''>Choose order</h1>
                <p className=''>Check over hundreds of menus to pick your favorite food.</p>
            </div>
            <div className='relative w-11/12 max-w-[550px]   '>
              <img src={comparedIMG}
                alt="Pattern"
                width={788}
                height={604}
                loading="lazy"/>
                <h1 className=''>Compared </h1>
                <p className=''>It's quick, safe, and simple. Select Compares methods of payment.</p>
            </div>
            <div className='relative w-11/12 max-w-[550px]   '>
              <img src={mealIMG}
                alt="Pattern"
                width={788}
                height={604}
                loading="lazy"/>
                <h1 className=''>Enjoy meals</h1>
                <p className=''>Food is made and delivered directly to your home.</p>
            </div>
          </div>
        
        </div>

        <div className='flex  flex-row-reverse justify-between gap-x-12 gap-y-0 py-40 w-screen h-screen bg-richblack-900 items-center'>
          <div className='w-11/12 max-w-[450px]'>
         <h className='text-richblack-5  font-semibold text-[1.875rem] leading-[2.375rem]'>
           no man on earth have ever reached their potential until some affirmation...!
         </h>
    <p className='text-[1.125rem] leading[2.625rem] mt-4'>
      <span className='text-richblack-100'>
        Findai revolutionizes your ordering experience by bringing all your favorite platforms to one sleek site,No more wandering through the maze of online stores – with Findai, it's all in one place. Effortlessly compare delivery times and prices across various platforms, empowering you to make informed decisions without the hassle.
      </span>
      <br />
      <br />
    
      <span className='text-blue-100 italic'>
        accurate and meaningful insights, fostering adaptability to various domains and ensuring efficient computation of similarities and differences.
      </span>
    </p>
    <br/>
          <br/>
          <div className='flex flex-row gap-7 mt-10 items-center '>
            <CTAButton active={true} linkto={"/signup"}> 
                Learn More
            </CTAButton>

            <CTAButton active={false} linkto={"/login"}> 
                Book a Demo
            </CTAButton>
        </div>
  </div>

  <div className='relative w-11/12 max-w-[550px]'>
    <img
      src={homeImg3}
      alt="Pattern"
      width={788}
      height={604}
      loading="lazy"
      className="rounded-lg shadow-lg"
    />
  </div>
</div>


<div className='flex  justify-between w-11/12 max-w-[1160px]  mx-auto gap-x-12 gap-y-0 py-40 flex-row-reverse'>
        <div className='w-11/12 max-w-[450px]'>
            <h className='text-bold font-semibold text-[1.875rem] leading-[2.375rem]'>
            Time-Consuming Searches:-
            </h>
            <p className='text-[1.125rem] leading[2.625rem] mt-4'>
              <span className='text-richblack-300'>
              Common individuals often spend a considerable amount of time manually visiting multiple websites to compare products, prices, or information, leading to frustration and inefficiency.              </span>

              <br/>
            <span className='text-blue-100 italic'>
            The abundance of information across various websites can overwhelm users, making it challenging to sift through and extract relevant details for informed decision-making.
            </span>
          </p>
          <br/>
          <br/>
          <Button/>

        </div>
  

        <div className='relative w-11/12 max-w-[550px]   '>
            <img src={homeImg3}
                alt="Pattern"
                width={788}
                height={604}
                loading="lazy"/>
                </div>
        </div>

        <div className='flex flex-row justify-between   mx-auto gap-x-12 gap-y-0 py-40 bg-richblack-900 justify-items-center'>
        <div className='w-11/12 max-w-[450px]'>
            <h className='text-richblack-5 font-semibold text-[1.875rem] leading-[2.375rem]'>
            Time-Consuming Searches:-
            </h>
            <p className='text-[1.125rem] leading[2.625rem] mt-4'>
              <span className='text-richblack-100'>
              Common individuals often spend a considerable amount of time manually visiting multiple websites to compare products, prices, or information, leading to frustration and inefficiency.              </span>

              <br/>
            <span className='text-blue-100 italic'>
            The abundance of information across various websites can overwhelm users, making it challenging to sift through and extract relevant details for informed decision-making.
            </span>
          </p>
          <br/>
          <br/>
          <div className='flex flex-row gap-7 mt-10 items-center '>
            <CTAButton active={true} linkto={"/signup"}> 
                Learn More
            </CTAButton>

            <CTAButton active={false} linkto={"/login"}> 
                Book a Demo
            </CTAButton>
        </div>

        </div>
  

        <div className='relative  w-11/12  '>
            <img src={homeImg4}
                alt="Pattern"
                width={588}
                height={404}
                loading="lazy"/>
                </div>
        </div>
     <div className='flex flex-row items-center  py-20  px-10 w-screen justify-evenly'>
      
      <div className=' flex flex-row justify-evenly'>
      <Card
        title="Sample Card Title"
        description="This is a sample card description. You can customize it as per your needs."
        price="$500"
        imageUrl="https://purepng.com/public/uploads/large/51505911091t5uwbalniw3bt5jhw7pcy0hh7xay7cvsljlmgmpkhpp0ftuxlnidrupgjio2xitymu1d65otrsqvracwl3f6elhkfbarwkzqmebl.png"
      />
      <Card
        title="Sample Card Title"
        description="This is a sample card description. You can customize it as per your needs."
        price="$500"
        imageUrl="https://th.bing.com/th/id/OIF.pNUWoAAK763fx9LVuI7ybw?rs=1&pid=ImgDetMain"
      />
      <Card
        title="Sample Card Title"
        description="This is a sample card description. You can customize it as per your needs."
        price="$500"
        imageUrl="https://th.bing.com/th/id/OIP.DvrLq5l12uWLsNXaVFgakAAAAA?rs=1&pid=ImgDetMain"
      />
      </div>
     
     </div>


     <div className='flex flex-row items-center  py-20  px-10 w-screen justify-evenly'>
      
      <div className=' flex flex-row justify-evenly'>
      <Card
        title="Normal Buriyani"
        description="This is a sample card description. You can customize it as per your needs."
        price="$500"
        imageUrl="https://th.bing.com/th/id/R.f934c8d53457ca23ec65f53988d5a8e8?rik=C8146wbdZ4LVQA&riu=http%3a%2f%2fwww.fashioncentral.pk%2fwp-content%2fuploads%2f2017%2f09%2fA-simple-biryani.jpg&ehk=UsDpm7hhr22ljI8DovVHAafXai2ZVk2X1kfwGntwI4E%3d&risl=&pid=ImgRaw&r=0"
      />
      <Card
        title="Dam Buriyani"
        description="This is a sample card description. You can customize it as per your needs."
        price="$500"
        imageUrl="https://th.bing.com/th/id/OIP.G-uwUXTAeFC5J8luKbITeAHaLH?pid=ImgDet&w=200&h=300&c=7&dpr=1.3"
      />
      <Card
        title="Spicy Buriyani"
        description="This is a sample card description. You can customize it as per your needs."
        price="$500"
        imageUrl="https://th.bing.com/th/id/OIP.iO6f-CKlh8nCwJNoOhm5xgHaLH?w=720&h=1080&rs=1&pid=ImgDetMain"
      />
      </div>
     
     </div>
     <div className='flex flex-row items-center  py-20  px-10 w-screen justify-evenly bg-black'>
      
      
        <p className=' font-extrabold text-lime-50'>this is chandan kumar</p>
        <h1 className=' font-extrabold text-lg'> this is oveze bhaiya and i m leaerning react js</h1>
       
     
     </div>
      
        </div>

     
    
  )
}

export default Home
