import React from 'react';

const Card = ({ title, description,price, imageUrl }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg">
      <div className="relative group mr-8">
        <img
          src={imageUrl}
          alt={title}
          className=" w-9/12 transition duration-300 ease-in-out transform group-hover:shadow-lg"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out flex justify-center items-center">
          {/* <p className="text-white font-bold">View Details</p> */}
        </div>
      </div>
      <div className="px-6 py-4 ">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-700 text-base">{description}</p>
        <p className="text-gray-700  text-lg  font-extrabold">{price}</p>
      </div>
    </div>
  );
};

export default Card;
