import React from 'react'
import Template from '../components/Template'
import loginImg from "../assets/LoginIMG1.png"

const Login = ({setIsLoggedIn}) => {
  return (
    <Template
      title="Welcome Back to FindAi.."
      // desc1="Introducing Findai, your ultimate time-saving companion for all Your AI need"
      // desc2="No more wandering through the maze of online stores – with Findai, it's all in one place."
      image={loginImg}
      formtype="login"
      setIsLoggedIn={setIsLoggedIn}
    />
  )
}

export default Login
