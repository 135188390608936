import React from 'react';
import AboutIMG from '../assets/AboutIMG1.jpg';


const About = () => {
  return (
    <div className=' text-rose-50 w-11/12 flex items-center justify-center flex-col'>
      <h1 className=' font-bold text-lg'>Introducing Findai</h1>
      <p>
        Welcome to our website! We are a team of passionate individuals dedicated to providing high-quality services/products to our customers.
      </p>
      <div className=''>
         <div>
            <p>

            </p>
         </div>
       
        <div className='relative w-11/12 max-w-[550px] pt-11  '>
            <img src={AboutIMG}
                alt="Pattern"
                width={458}
                height={140}
                loading="lazy"/>
          </div>
        

       

      </div>
    </div>
    
  );
};

export default About;
