// Footer.js

import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-8">
            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-4">
                <div className="mb-4 md:mb-0">
                    <h2 className="text-xl font-bold">findAi.co.in</h2>
                    <p className="text-sm">123 Street, Delhi</p>
                </div>
                <nav className="flex space-x-4 ">
                    <a href="https://www.linkedin.com/company/snapeye/" className="hover:text-gray-300">linkedin</a>
                    <a href="https://findai.co.in/" className="hover:text-gray-300">website</a>
                    <a href="https://www.linkedin.com/in/chandan-kumar-sagar-2343a8204/?originalSubdomain=in" className="hover:text-gray-300">Career</a>
                    <a href="https://github.com/chandan-kumar-sagar" className="hover:text-gray-300">Contact</a>
                </nav>
            </div>
            <div className="mt-4 text-center text-sm">
                &copy; 2024 Company Name. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
