import React from 'react'

const Dashboard = () => {
  return (
    <div className='flex justify-center items-center text-white text-3xl h-full'>
      Welcome to FindAi!!
    </div>
  )
}

export default Dashboard
