import React from 'react'
import signupImg from "../assets/SignUPIMG1.png"
import Template from '../components/Template'

const Signup = ({setIsLoggedIn}) => {
  return (
    <Template
      title="Welcome TO Sign Up.."
      desc1="Introducing Findai, your ultimate time-saving companion!"
      desc2="No more wandering through the maze of online stores – with Findai, it's all in one place."
      image={signupImg}
      formtype="signup"
      setIsLoggedIn={setIsLoggedIn}
    />
  )
}

export default Signup
