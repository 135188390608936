import React, { Component } from 'react';

class AgreementCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      checked: event.target.checked
    });
  }

  render() {
    return (
      <label>
        <input className='  w-[18px] h-[18px]'
          type="checkbox"
          checked={this.state.checked}
          onChange={this.handleChange}
        />
        I agree to your Terms and Privacy policy
      </label>
    );
  }
}

export default AgreementCheckbox;